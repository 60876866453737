import React from "react";
import Modal from "react-bootstrap/Modal";

//config
import config from "../../../config";

// components
import QrCode from "../../QrCode/QrCode";

// styles
import * as styles from "./DoctorPortal.module.css";

const DoctorDetailModal = props => {
  const downloadQrCode = () => {
    const canvas = document.getElementById("QRCODE");
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `${props.doctorDetail.indiaClinicId}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        fullscreen={true}
        scrollable={true}
        contentClassName={styles.docDetail__modal}
      >
        <Modal.Body>
          <div className={styles.docDetail__modalClose}>
            <span
              className="icon-cross"
              aria-hidden="true"
              role="presentation"
              onClick={props.onHide}
            ></span>
          </div>
          <div>
            <div className={styles.docDetail__modalDetails}>
              <h3>India Clinic Id</h3>
              <p>{props.doctorDetail.indiaClinicId}</p>
            </div>
            <div className={styles.docDetail__modalDetails}>
              <h3>Name</h3>
              <p>{props.doctorDetail.name}</p>
            </div>
            <div className={styles.docDetail__modalDetails}>
              <h3>Email Id</h3>
              <p>{props.doctorDetail.email}</p>
            </div>
            <div className={styles.docDetail__modalDetails}>
              <h3>Phone Number</h3>
              <p>{props.doctorDetail.doctorPhoneNumber}</p>
            </div>
            <div className={styles.docDetail__modalDetails}>
              <h3>Medical Registration Number</h3>
              <p>{props.doctorDetail.medicalRegistartionNumber}</p>
            </div>
            <div className={styles.docDetail__modalDetails}>
              <h3>Profile Photo</h3>
              <p>{props.doctorDetail.profilePhoto}</p>
            </div>
            <div className={styles.docDetail__modalDetails}>
              <h3>Gender</h3>
              <p>{props.doctorDetail.gender}</p>
            </div>
            <div className={styles.docDetail__modalDetails}>
              <h3>Designation</h3>
              <p>{props.doctorDetail.designation}</p>
            </div>
            <div className={styles.docDetail__modalDetails}>
              <h3>Experience</h3>
              <p>{props.doctorDetail.experience}</p>
            </div>
            <div className={styles.docDetail__modalDetails}>
              <h3>Specialisation</h3>
              <p>{props.doctorDetail.doctorSpecialization}</p>
            </div>
            <div className={styles.docDetail__modalDetails}>
              <h3>Description</h3>
              <p>{props.doctorDetail.description}</p>
            </div>
            <div className={styles.docDetail__modalDetails}>
              <h3>Hospital Name</h3>
              <p>{props.doctorDetail.doctorHospitalName}</p>
            </div>
            <div className={styles.docDetail__modalDetails}>
              <h3>Landing Page Url</h3>
              <p>
                {config.DX_UI_URL + props.doctorDetail.doctorLandingPageUrl}
              </p>
            </div>
            <div className={styles.docDetail__modalDetails}>
              <h3>Assistant Name</h3>
              <p>{props.doctorDetail.doctorAssistantName}</p>
            </div>
            <div className={styles.docDetail__modalDetails}>
              <h3>Assistant Number</h3>
              <p>{props.doctorDetail.doctorAssistantNumber}</p>
            </div>
            <div className={styles.docDetail__modalDetails}>
              <h3>Address Line</h3>
              <p>{props.doctorDetail.address}</p>
            </div>
            <div className={styles.docDetail__modalDetails}>
              <h3>City</h3>
              <p>{props.doctorDetail.city}</p>
            </div>
            <div className={styles.docDetail__modalDetails}>
              <h3>State</h3>
              <p>{props.doctorDetail.state}</p>
            </div>
            <div className={styles.docDetail__modalDetails}>
              <h3>Pincode</h3>
              <p>{props.doctorDetail.pincode}</p>
            </div>
            <div className={styles.docDetail__modalDetails}>
              <h3>QR Code</h3>
              <QrCode
                qrValue={
                  config.DX_UI_URL + props.doctorDetail.doctorLandingPageUrl
                }
                size="200"
                qrStyle="squares"
              />
            </div>
            <div className={styles.docDetail__qrDownloadCta}>
              <button type="button" onClick={downloadQrCode}>
                Download QR Code
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DoctorDetailModal;
