// extracted by mini-css-extract-plugin
export var docDetail__modal = "DoctorPortal-module--docDetail__modal--o7kzu";
export var docDetail__modalClose = "DoctorPortal-module--docDetail__modalClose--iwMb5";
export var docDetail__modalDetails = "DoctorPortal-module--docDetail__modalDetails--QOzHf";
export var docDetail__qrDownloadCta = "DoctorPortal-module--docDetail__qrDownloadCta--Ns5zS";
export var docPortal__cont = "DoctorPortal-module--docPortal__cont--C2fAe";
export var docPortal__contHdng = "DoctorPortal-module--docPortal__contHdng--qUc5U";
export var docPortal__docDetailSec = "DoctorPortal-module--docPortal__docDetailSec--6-jI2";
export var docPortal__docDetailSec__dropDown = "DoctorPortal-module--docPortal__docDetailSec__dropDown--we7ee";
export var docPortal__docDetailSec__form = "DoctorPortal-module--docPortal__docDetailSec__form--3cP2p";
export var docPortal__docDetailSec__formCta = "DoctorPortal-module--docPortal__docDetailSec__formCta--aIYM9";
export var docPortal__docInpSec = "DoctorPortal-module--docPortal__docInpSec--rLV5S";
export var docPortal__loaderPara = "DoctorPortal-module--docPortal__loaderPara--aPmCf";