import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { Link } from "gatsby";

// config
import config from "../../../config";

// component
import DoctorDetailModal from "./DoctorDetailModal";

// css
import * as styles from "./DoctorPortal.module.css";

const DoctorDetail = props => {
  const [showDoctorDetailModal, setShowDoctorDetailModal] = useState(false);
  const [doctorDetail, setDoctorDetail] = useState({});

  const toggleDetailModal = data => {
    setDoctorDetail(data);
    setShowDoctorDetailModal(!showDoctorDetailModal);
  };
  return (
    <div className={`container ${styles.docPortal__cont}`}>
      <div className="row">
        <div className="col-12">
          {props.doctorList ? (
            <div>
              <div className={`text-center ${styles.docPortal__contHdng}`}>
                <h3>List Of Doctors</h3>
              </div>
              <div>
                <Table striped bordered hover variant="dark">
                  <thead>
                    <tr>
                      <th>Dx Id</th>
                      <th>Icap Id</th>
                      <th>Name</th>
                      <th>Medical Reg No</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Designation</th>
                      <th>Landing Page Url</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.doctorList.data &&
                      props.doctorList.data.map(data => (
                        <tr key={data.id}>
                          <td
                            style={{ cursor: "pointer", color: "#f37920" }}
                            onClick={() => {
                              toggleDetailModal(data);
                            }}
                            role="presentation"
                          >
                            {data.id}
                          </td>
                          <td>{data.indiaClinicId}</td>
                          <td>{data.name}</td>
                          <td>{data.medicalRegistartionNumber}</td>
                          <td>{data.email}</td>
                          <td>{data.doctorPhoneNumber}</td>
                          <td>{data.designation}</td>
                          <td>
                            <Link
                              to={config.DX_UI_URL + data.doctorLandingPageUrl}
                              target="_blank"
                              style={{ color: "#f37920" }}
                            >
                              {config.DX_UI_URL + data.doctorLandingPageUrl}
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <DoctorDetailModal
        show={showDoctorDetailModal}
        onHide={toggleDetailModal}
        doctorDetail={doctorDetail}
      />
    </div>
  );
};

export default DoctorDetail;
