import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";

// seo
import Seo from "../../components/Seo";

// utils
import localStorage from "../../utils/LocalStorageService";

// actions
import { getAllDoctorsListForDoctorLp } from "../../actions/doctorReferralActions";

// components
import HeaderWithLogo from "../../components/Header/HeaderWithLogo";
import DoctorDetail from "../../components/DoctorLandingPage/DoctorPortal/DoctorDetail";

const DoctorDetailPage = () => {
  const [doctorList, setDoctorList] = useState([]);
  useEffect(() => {
    const fetchDoctorList = async () => {
      const response = await getAllDoctorsListForDoctorLp();
      setDoctorList(response);
    };
    fetchDoctorList();
  }, []);

  // Function ->> Restrict(logout) user to move back and forth when user clicks the browser back button
  useEffect(() => {
    window.addEventListener("popstate", handleLogout);

    return () => {
      window.removeEventListener("popstate", handleLogout);
    };
  }, []);

  // Function ->> To Logout The User
  const handleLogout = () => {
    localStorage.clearToken();
    navigate("/doctor/login"); // redirect to doctor lp login page
  };

  return (
    <div className="bgDarkBlue" style={{ minHeight: "100vh" }}>
      <Seo
        title="Reverse Diabetes @ Twin | Book an appointment with India's leading Doctors"
        description="Is Diabetes Reversal possible? Book a Personalised Video Consultation with Top Diabetologists & get all your Diabetes-related queries answered."
      />
      <HeaderWithLogo logoPosition="center" />
      <DoctorDetail doctorList={doctorList} />
    </div>
  );
};

export default DoctorDetailPage;
