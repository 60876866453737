import React from "react";
import { QRCode } from "react-qrcode-logo";
import PropTypes from "prop-types";

// image
import logoImage from "../../images/twin-logo/twinLogo6_2024.png";

// css
import * as styles from "./QrCode.module.css";

const propTypes = {
  value: PropTypes.string,
  size: PropTypes.string,
  qrStyle: PropTypes.string,
};

const defaultProps = {
  value: "https://ind.twinhealth.com/",
  size: "200",
  qrStyle: "dots"
};

const QrCode = props => {
  return (
    <>
      {/* qr-code */}
      <div className={styles.qrCode__sec}>
        <QRCode
          value={props.qrValue}
          size={props.size}
          logoImage={logoImage}
          logoWidth={60}
          logoHeight={60}
          enableCORS={true} // enabling CORS, this is the thing that will bypass that DOM check
          qrStyle={props.qrStyle} // type of qr code, wether you want dotted ones or the square ones
          eyeRadius={10} // radius of the promocode eye
          id={"QRCODE"}
        />
      </div>
    </>
  );
};

QrCode.propTypes = propTypes;
QrCode.defaultProps = defaultProps;

export default QrCode;

// How to use
// <QrCode qrValue="xyz.com" size="200" qrStyle="squares||dots"
